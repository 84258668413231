<template>
  <div>
    <el-dialog
      width="460px"
      center
      title="支付"
      :visible.sync="visible"
      @closed="onClose"
    >
      <div class="payInner">
        <ul class="choosePayStyle">
          <li
            v-for="item in PayStyleOpt"
            v-show="chooseStyle == item.key"
            @click="choosePayStyle(item)"
            :key="item.key"
            :class="{ active: chooseStyle == item.key }"
          >
            <i
              class="iconfont"
              :class="{
                aliPayColor: item.key == 'alipay',
                weChatColor: item.key == 'wechat',
                moneyBagColor: item.key == 'moneyBag',
              }"
              v-html="item.icon"
            >
            </i>
            <span>{{ item.label }}</span>
          </li>
        </ul>
        <!-- 选择支付宝/微信 -->
        <div class="code" v-if="chooseStyle != 'moneyBag'" key="NOmoneyBag">
          <!-- 微信支付二维码 -->
          <div ref="qrcode" id="qrcode" v-if="chooseStyle == 'wechat'"></div>
          <!-- 支付宝支付二维码 -->
          <iframe 
              v-if="chooseStyle == 'alipay'"
              :srcdoc="payAliQRcode" 
              frameborder="no" 
              border="0"
              marginwidth="0"
              marginheight="0"
              scrolling="no" 
              width="100" 
              height="100"
              style="overflow: hidden;"
              sandbox="allow-scripts allow-top-navigation allow-same-origin allow-forms"
              target="_parent"
              >
          </iframe>
          <div ref="alipayWap" v-html="alipayForm" />
        </div>

        <!-- 选择钱包 -->
        <div class="moneyBag" key="moneyBag" v-else>
          <ul class="baseUl">
            <li>
              <span> 当前余额（元）：</span>
              <span class="price"> 1000 </span>
            </li>
            <li>
              <span> 需支付（元）：</span>
              <span class="price"> 198 </span>
            </li>
          </ul>
          <div class="btnWrap">
            <el-button type="primary">支付</el-button>
          </div>
        </div>
        <!-- 提示 -->
        <div class="tips">支付完成之时起5分钟内生效</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { queryPaymentOrder,queryCheckPaymentOrder,queryDCIPaymentOrder,paymentRegisterOrder } from "@/api/EvidenceService";
export default {
  name: "",
  props: {
    payForm: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 弹框是否显示
      visible: false,
      chooseStyle: "wechat",
      orderInfo: null,
      orderStatus: "10",
      checkStatusId: -1,
      paymentRequest: null,
      PayStyleOpt: [
        {
          key: "alipay",
          label: "支付宝支付",
          icon: "&#xe675;",
        },
        {
          key: "wechat",
          label: "微信支付",
          icon: "&#xe678;",
        },
        // {
        //   key: "moneyBag",
        //   label: "余额支付",
        //   icon: "&#xe632; ",
        // },
      ],
      payAliQRcode: '',
      alipayForm: "",
    };
  },
  created() {
    // this.visible = true
  },
  beforeDestroy() {
    // console.log('beforeDestroy');
    let that = this;
    if (that.checkStatusId != -1) {
      clearInterval(that.checkStatusId);
      that.checkStatusId = -1;
    }
  },
  methods: {
    choosePayStyle(item) {
      this.chooseStyle = item.key;

    },
    // 关闭弹框时触发的事件
    onClose() {
      this.$emit("close", this.orderStatus == "21");
      if(this.chooseStyle == 'wechat'){
        document.getElementById("qrcode").innerHTML = "";
      }
    },
    queryOrderStatus() {
      let that = this;
      if (that.checkStatusId != -1) {
        clearInterval(that.checkStatusId);
      }
      let checkStatus = () => {
        console.log('--------------payForm---------------:',this.payForm);
        if(this.payForm == 'register'){
          queryPaymentOrder(that.orderInfo.paymentOrderNo).then(({ data }) => {
            if (data.paymentStatus == "21") {
              //已支付
              clearInterval(that.checkStatusId);
              that.orderStatus = "21";
              that.visible = false;
            }
          });
        }else if(this.payForm == 'check'){
          queryCheckPaymentOrder(that.orderInfo.orderNo).then(({ data }) => {
            // if (data.paymentStatus == "21") {
            if (data.orderStatus == "12") {
              //已支付
              clearInterval(that.checkStatusId);
              that.orderStatus = "21";
              that.visible = false;
            }
          });
        }else if(this.payForm == 'DCI'){
          queryDCIPaymentOrder(that.orderInfo.orderNo).then(({ data }) => {
            if (data.paymentStatus == "21") {
              //已支付
              clearInterval(that.checkStatusId);
              that.orderStatus = "21";
              that.visible = false;
            }
          });
        }else if(this.payForm == 'DCIRegister'){
          paymentRegisterOrder(that.orderInfo.orderNo).then(({ data }) => {
            if (data.paymentStatus == "21") {
              //已支付
              clearInterval(that.checkStatusId);
              that.orderStatus = "21";
              that.visible = false;
            }
          });
        }else{
          queryPaymentOrder(that.orderInfo.paymentOrderNo).then(({ data }) => {
          if (data.paymentStatus == "21") {
            //已支付
            clearInterval(that.checkStatusId);
            that.orderStatus = "21";
            that.visible = false;
          }
        });
        }
        
      };

      that.checkStatusId = setInterval(checkStatus, 5000);
    },
    showDialog(orderInfo) {
      this.orderInfo = orderInfo;
      this.chooseStyle = orderInfo.paymode;
      this.paymentRequest = orderInfo.paymentRequest;
      this.visible = true;
      if (orderInfo.paymode == "wechat") {  //微信支付
        setTimeout(() => {
          // console.log("hello..");
          console.log('微信支付二维码：',this.paymentRequest.payResponse.code_url);
          var qrcode = new QRCode("qrcode", {
            text: this.paymentRequest.payResponse.code_url, // 需要转换为二维码的内容
            width: 100,
            height: 100,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }, 400);

        this.queryOrderStatus();
      }else if (orderInfo.paymode == "alipay") {  //支付宝支付
        this.queryOrderStatus();
        //console.log(this.paymentRequest.payResponse);
        this.alipayForm = this.paymentRequest.payResponse.form;
        this.$nextTick(() => {
          //document.forms["_alipaysubmit_"].setAttribute("target", "_blank");
          this.$refs.alipayWap.children[0].setAttribute("target", "_blank");
          this.$refs.alipayWap.children[0].submit();
          this.visible = false;
        });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.payInner {
  color: rgba(0, 18, 22, 0.65);
  font-size: 16px;
  padding-bottom: 16px;
  /deep/ .el-dialog__header .el-dialog__title {
    font-size: 24px;
  }
  .choosePayStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 32px;
      margin-right: 4px;
      line-height: 1em;
    }
    li {
      display: flex;
      margin: 0 8px;
      align-items: center;
      border: solid 1px rgba(0, 18, 22, 0.2);
      border-radius: 2px;
      padding: 2px 8px;
    }
    li.active {
      color: #007DFF;
      border-color: #007DFF;
    }
  }
  .price {
    color: rgba(0, 18, 22, 0.85);
    font-weight: bold;
    font-size: 20px;
  }
  .moneyBag {
    padding: 48px 48px 0;
  }
  .btnWrap {
    margin: 32px 0 16px;
    .el-button--primary {
      width: 100%;
    }
  }
  .tips {
    text-align: center;
  }
  .code {
    display: flex;
    justify-content: center;
    margin: 36px 0 24px;
    img {
      width: 144px;
    }
  }
}

@media screen and (max-width: 991px){
  /deep/.el-dialog--center{width: 90%!important;}
}
</style>
